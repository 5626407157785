<template>
  <!-- 页面header -->
  <el-header class="website-header" height="64px">
    <el-row class="header-center">
      <!-- 页面header-左侧 -->
      <el-col :span="12" class="website-header-left">
        <img src="@/assets/img/logo.png" alt="" />
        <h3 class="home-title">安徽省模式识别信息技术有限公司</h3>
      </el-col>
      <!-- 页面header-右侧 -->
      <el-col :span="8" :offset="4" class="website-header-right">
        <ul>
          <li :class="{ 'click-li': clickLi == 0 }" @click="handleLi(0)">首页</li>
          <li :class="{ 'click-li': clickLi == 1 }" @click="handleLi(1)">产品服务</li>
          <li :class="{ 'click-li': clickLi == 2 }" @click="handleLi(2)">关于我们</li>
          <li :class="{ 'click-li': clickLi == 3 }" @click="handleLi(3)">加入我们</li>
        </ul>
      </el-col>
    </el-row>
  </el-header>
</template>

<script>
export default {
  data() {
    return {
      clickLi: 0
    };
  },
  mounted() {
    if (sessionStorage.getItem('clickLi')) {
      this.clickLi = sessionStorage.getItem('clickLi');
      return;
    }
    this.clickLi = 0;
  },
  methods: {
    handleLi(index) {
      this.clickLi = index;
      sessionStorage.setItem('clickLi', this.clickLi);
      switch (index) {
        case 0:
          this.$router.push({
            path: '/home'
          });
          break;
        case 1:
          this.$router.push({
            path: '/service'
          });
          break;
        case 2:
          this.$router.push({
            path: '/about'
          });
          break;
        case 3:
          this.$router.push({
            path: '/join'
          });
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
.website-header {
  width: calc(100vw - 10px);
  min-width: 1380px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 99;
}

.header-center {
  width: 1200px;
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.home-title {
  position: absolute;
  display: inline-block;
  font-size: 23px;
  top: 16px;
  left: 66px;
}
.website-header-left {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    height: 34px;
  }
}

.website-header-right {
  height: 100%;
}

.website-header-right > ul {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.website-header-right > ul > li {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #181818;
  line-height: 20px;
  cursor: pointer;
  position: relative;
}

.website-header-right > ul > li:hover {
  font-weight: 600;
}

.click-li {
  font-weight: 600;
}

.click-li::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  background: #1b49d4;
  border-radius: 100px 100px 0px 0px;
  top: 41px;
  left: 0;
}
</style>
