import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'homeView',
        component: () =>
            import ('@/views/home.vue')
    },
    {
        path: '/service',
        name: '/serviceView',
        component: () =>
            import ('@/views/service.vue')
    },
    {
        path: '/about',
        name: 'aboutView',
        component: () =>
            import ('@/views/about.vue')
    },
    {
        path: '/join',
        name: 'joinView',
        component: () =>
            import ('@/views/join.vue')
    }, {
        path: '/news',
        name: 'newsView',
        component: () =>
            import ('@/views/news.vue')
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router