<template>
  <el-footer height="60px" class="website-footer">
    <p>
      Copyright © 2022 - 2022 安徽省模式识别科技 版权所有 <a class="url-a" href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2022011699号-1</a>
    </p>
  </el-footer>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.website-footer {
  width: calc(100vw - 10px);
  min-width: 1380px;
  text-align: center;
  background: #000000;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
}
.url-a {
  color: #ffffff;
  text-decoration: none;
}
</style>
