<template>
  <div id="app">
    <!-- 官网header -->
    <website-header></website-header>
    <router-view></router-view>

    <!-- 官网footer -->
    <website-footer></website-footer>
  </div>
</template>

<script>
    import WebsiteHeader from '@/components/website-header.vue'
    import WebsiteFooter from '@/components/website-footer.vue'
    import AOS from 'aos'
    import 'aos/dist/aos.css'

    export default {
        name: 'App',
        components: {
            WebsiteHeader,
            WebsiteFooter
        },
        mounted() {
            AOS.init();
        }
    }
</script>

<style lang="less">
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
     ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 5px;
        scrollbar-arrow-color: red;
    }
    
     ::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
        scrollbar-arrow-color: red;
    }
    
     ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
        border-radius: 0;
        background: rgba(0, 0, 0, 0);
    }
</style>