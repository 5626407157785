import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import router from '@/router/index'
import 'element-ui/lib/theme-chalk/index.css'
import BaiduMap from 'vue-baidu-map'
import AOS from 'aos'
import 'aos/dist/aos.css'

import {
    Button,
    Header,
    Footer,
    Row,
    Col,
    Carousel,
    CarouselItem,
    Tooltip,
    Image
} from 'element-ui'


Vue.use(Button)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Row)
Vue.use(Col)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tooltip)
Vue.use(Image)

Vue.use(BaiduMap, {
    ak: 'ThSKjMmgeFzoiAXv7PQRyoZs52dvz4BZ'
})
Vue.use(AOS)

new Vue({
    render: h => h(App),
    router
}).$mount('#app')